import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/emergency.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Emergency Response Teams | Camelo"
        metaDescription="Make sure your emergency response staff is always on shift. Send the right staff to the right place, in time, with the Camelo scheduling software."
        header="Send the right staff to the right place, in time."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
